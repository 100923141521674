import { BrandColorBscSwap } from '@uikit/icons/BrandColorBscSwap';
import { BrandColorDexTools } from '@uikit/icons/BrandColorDexTools';
import { BrandColorGateIo } from '@uikit/icons/BrandColorGateIo';
import { KucoinLogo } from '@uikit/icons/KucoinLogo';
import { MultiColorBybit } from '@uikit/icons/MultiColorBybit';
import { MultiColorCoingecko } from '@uikit/icons/MultiColorCoingecko';
import { MultiColorCoinmarketcap } from '@uikit/icons/MultiColorCoinmarketcap';
import { MultiColorPancakeSwap } from '@uikit/icons/MultiColorPancakeSwap';

export const TOKEN_SOURCES = {
  kuCoin: {
    label: 'KuCoin',
    Icon: KucoinLogo,
    href: 'https://www.kucoin.com/trade/SQR-USDT?rcode=rMCZ2UD',
  },
  byBit: {
    label: 'Bybit',
    Icon: MultiColorBybit,
    href: 'https://partner.bybit.com/b/sqrspot',
  },
  pancakeSwap: {
    label: 'PancakeSwap',
    Icon: MultiColorPancakeSwap,
    href: 'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x2B72867c32CF673F7b02d208B26889fEd353B1f8&chain=bsc',
  },
  gateIo: {
    label: 'Gate.io',
    Icon: BrandColorGateIo,
    href: 'https://www.gate.io/en/trade/SQR_USDT?ref=AlBAU1Fd',
  },
};

export const sqrTokenSourcesInOrder = [
  TOKEN_SOURCES.kuCoin,
  TOKEN_SOURCES.byBit,
  TOKEN_SOURCES.gateIo,
  TOKEN_SOURCES.pancakeSwap,
  {
    label: 'CoinMarketCap',
    Icon: MultiColorCoinmarketcap,
    href: 'https://coinmarketcap.com/currencies/magic-square/',
  },
  {
    label: 'CoinGecko',
    Icon: MultiColorCoingecko,
    href: 'https://www.coingecko.com/en/coins/magic-square',
  },
  {
    label: 'DexTools',
    Icon: BrandColorDexTools,
    href: 'https://www.dextools.io/app/en/bnb/pair-explorer/0xb2657f72fb6d66a5efcab957c4ec0ee30d46d826',
  },
  {
    label: 'BSCScan',
    Icon: BrandColorBscSwap,
    href: 'https://bscscan.com/token/0x2b72867c32cf673f7b02d208b26889fed353b1f8',
  },
];
